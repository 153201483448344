.tituloMenu{
    font-size: 48px;
    line-height: 48px;
    text-transform: none;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: center;
    padding-top: 6rem;
    padding-bottom: 1rem;
    margin: 0;
}
.subtituloMenu {
    font-size: 32px;
    font-weight: 400;
    line-height: 30px;
    text-transform: none;
    color: #73848e;
    margin-top: 35px;
    margin-bottom: 50px;
    text-align: center;
}
.cardContainer {
        
    padding-left: 0rem;
    padding-right: 0rem;
}
.bgMenu {
    background-color: rgb(244,246,231); 
    height: 100%;
    padding: 0rem 10rem 5rem; 
}

@media all and (max-width: 1288px) { 
    .bgMenu {
        padding: 0rem 3rem 2rem; 
    }
} 
@media all and (max-width: 941px) { 
    .columna {
        margin-left: 2rem;
        width: 100% !important;
    }
} 
