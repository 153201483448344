@font-face {
  font-family: 'dubba-dubba';
  font-style: normal;
  font-weight: normal;
  src:
    url('./Fonts/dubba-dubba-nf/DubbaDubbaNF.otf') format('otf'),
    url('./Fonts/dubba-dubba-nf/DubbaDubbaNF.ttf') format('truetype')
}
body {
  margin: 0;
  padding: 0;
  font-family: 'dubba-dubba' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
