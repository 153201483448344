.LogoImg {
    width: 13rem;
    margin: 0 auto;
}
.titleContainer {
    position: absolute; 
    top: 0px; 
    left: 0; 
    right: 0; 
    margin: 0 auto;
}
.imgFondo{
    width: 100%; 
    height: 10%; 
    margin-bottom: 0;
}
.tituloVeg{
    font-size: 6rem;
    margin-bottom: 1rem;
}
.textTitle {
    color: white;
    font-size: 3rem;
}
.textCarnivoro {
    font-size: 6rem;
    margin-top: .25rem;
    padding-bottom: .25rem;
}

@media all and (max-width: 1024px) { 
    .LogoImg {
        width: 8rem;
        margin: 0 auto;
    }
    .tituloVeg{
        font-size: 6rem;
        margin-bottom: 0.5rem;
    }
    .textTitle {
        color: white;
        font-size: 2rem;
    }
    .textCarnivoro {
        font-size: 6rem;
        margin-top: .25rem;
        padding-bottom: .5rem;
    }

} 
 @media all and (max-width: 750px) {
    .tituloVeg{
        font-size: 3rem;
        margin-bottom: 0.5rem;
    }
    .LogoImg {
        width: 4rem;
        margin: 0 auto;
    }
    .textTitle {
        color: white;
        font-size: 1.5rem;
    }
    .textCarnivoro {
        font-size: 3rem;
        margin-top: .05rem;
        padding-bottom: .25rem;
    }
}
.tituloMenu{
    font-size: 48px;
    line-height: 48px;
    text-transform: none;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: center;
    padding-top: 6rem;
    padding-bottom: 1rem;
    margin: 0;
}
.subtituloMenu {
    font-size: 32px;
    font-weight: 400;
    line-height: 30px;
    text-transform: none;
    color: #73848e;
    margin-top: 35px;
    margin-bottom: 50px;
    text-align: center;
}
.cardContainer {
        
    padding-left: 0rem;
    padding-right: 0rem;
}
.bgMenu {
    background-color: rgb(244,246,231); 
    height: 100%;
    padding: 0rem 10rem 5rem; 
}

@media all and (max-width: 1288px) { 
    .bgMenu {
        padding: 0rem 3rem 2rem; 
    }
} 
@media all and (max-width: 941px) { 
    .columna {
        margin-left: 2rem;
        width: 100% !important;
    }
} 

.bgCardMenu{
    color: black
}
.tituloCardMenu{
    font-size: 32px;
    line-height: 44px;
}
.card2line {
    display: flex;
    justify-content: space-between;
}
.subtituloCardMenu {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 30px;
}
.precio {
    font-size: 18px;
    font-weight: bold;
    color: rgb(145,168,49);
    letter-spacing: 1px;
}

@media all and (max-width: 941px) { 
    .bgMenu {
        
        height: 100%;
        padding: 0rem 3rem 2rem; 
    }
}
@media all and (max-width: 633px) { 
    .bgMenu {
        margin-top: 1rem;
        height: 100%;
    }
    .tituloCardMenu{
        font-size: 28px;
        line-height: 35px;
        margin-top: 1rem;
    }
    .subtituloCardMenu {
        line-height: 20px;
    }
    .lineaPuntos {
        display:none;
    }
}
@font-face {
  font-family: 'dubba-dubba';
  font-style: normal;
  font-weight: normal;
  src:
    url(/static/media/DubbaDubbaNF.e31b4c39.otf) format('otf'),
    url(/static/media/DubbaDubbaNF.23f3aba6.ttf) format('truetype')
}
body {
  margin: 0;
  padding: 0;
  font-family: 'dubba-dubba' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

