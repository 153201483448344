.bgCardMenu{
    color: black
}
.tituloCardMenu{
    font-size: 32px;
    line-height: 44px;
}
.card2line {
    display: flex;
    justify-content: space-between;
}
.subtituloCardMenu {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 30px;
}
.precio {
    font-size: 18px;
    font-weight: bold;
    color: rgb(145,168,49);
    letter-spacing: 1px;
}

@media all and (max-width: 941px) { 
    .bgMenu {
        
        height: 100%;
        padding: 0rem 3rem 2rem; 
    }
}
@media all and (max-width: 633px) { 
    .bgMenu {
        margin-top: 1rem;
        height: 100%;
    }
    .tituloCardMenu{
        font-size: 28px;
        line-height: 35px;
        margin-top: 1rem;
    }
    .subtituloCardMenu {
        line-height: 20px;
    }
    .lineaPuntos {
        display:none;
    }
}