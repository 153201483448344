.LogoImg {
    width: 13rem;
    margin: 0 auto;
}
.titleContainer {
    position: absolute; 
    top: 0px; 
    left: 0; 
    right: 0; 
    margin: 0 auto;
}
.imgFondo{
    width: 100%; 
    height: 10%; 
    margin-bottom: 0;
}
.tituloVeg{
    font-size: 6rem;
    margin-bottom: 1rem;
}
.textTitle {
    color: white;
    font-size: 3rem;
}
.textCarnivoro {
    font-size: 6rem;
    margin-top: .25rem;
    padding-bottom: .25rem;
}

@media all and (max-width: 1024px) { 
    .LogoImg {
        width: 8rem;
        margin: 0 auto;
    }
    .tituloVeg{
        font-size: 6rem;
        margin-bottom: 0.5rem;
    }
    .textTitle {
        color: white;
        font-size: 2rem;
    }
    .textCarnivoro {
        font-size: 6rem;
        margin-top: .25rem;
        padding-bottom: .5rem;
    }

} 
 @media all and (max-width: 750px) {
    .tituloVeg{
        font-size: 3rem;
        margin-bottom: 0.5rem;
    }
    .LogoImg {
        width: 4rem;
        margin: 0 auto;
    }
    .textTitle {
        color: white;
        font-size: 1.5rem;
    }
    .textCarnivoro {
        font-size: 3rem;
        margin-top: .05rem;
        padding-bottom: .25rem;
    }
}